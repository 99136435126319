.Container {
  background-image: url("../../assets/Virtue/Virtue.png");
  display: flex;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  justify-content: center;
  animation: fadeIn 1s ease-out;
  min-height: 100vh;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.LeftContainer {
  flex: 1;
  padding: 130px 110px;
  animation: 5s anim-lineUp ease-out;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.BulletListContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.BulletConnector {
  margin-left: 5px;
}

.SubHeader {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  opacity: 0.8;
  font-size: 40px;
  line-height: 59px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  margin-top: 5px;
  margin-left: 8px;
  color: #faf6e2;
}

.Header {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #faf6e2;
  opacity: 0.8;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.Titles {
  margin-left: 25px;
}

.Space {
  padding: 20px;
}

.ScrollContainer {
  width: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
}

.ScrollText {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #faf6e2;
  opacity: 0.8;
  border-bottom: 2px solid#F879BD;
}

.line {
  height: 20px;
  width: 2px;
  background-color: #f879bd;
}

.diamond {
  box-sizing: border-box;
  width: 10px;
  height: 10px;
  border: 1.53px solid #faf6e2;
  transform: rotateZ(45deg);
}

@media screen and (max-width: 600px){
  .Container{
    background-image: none;
    background: #494137EB;
  }
  .Titles{
    margin-left: 5px;
  }
  .LeftContainer{
    padding:130px 50px;
  }
  .Quotes{
    width: 370px ;
    height:400px ;
  }
}

@media screen and (max-width: 960px){
  .Container{
    background-image: none;
    background: #494137EB;
  }
}