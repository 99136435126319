.main {
  min-height: 100vh;
  width: 100vw;
  background-color: #faf6e2;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease-out;
}

.heading {
  font-size: 32px;
  font-weight: 400;
  opacity: 0.8;
  animation: anim-lineUp 2.5s ease-out;
  font-family: "Open Sans", sans-serif;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
