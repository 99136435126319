.main{
        /* background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../assets/HamburgerMenuScreen.png"); */
        background-image: url("../../assets/Navbar/Background.png");  
        background-repeat: no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100vw;
        background-position: center center;
        overflow: hidden;
        position: fixed;
        top:0;
        left:0;
        animation: fadeIn 1s ease-out;
        z-index: 100;
    }

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.crossLogo{
    position: absolute;
    top:8%;
    cursor: pointer;
    left:5%;
}

.btn{
    position: absolute;
    top:10%;
    cursor: pointer;
    right:5%;
}

.itemsList{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
}

.itemsList div{
    font-family: 'Open Sans', sans-serif;
    --border-color: #F879BD;
    --border-width: 3px;
    --bottom-distance: 0px;
    font-size: 40px;
    font-weight: 300;
    cursor: pointer;
    margin-bottom: 3%;
    display: inline-block;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    animation: 2s anim-lineUp ease-out;
    color:#FAF6E2;
    opacity: 0.8;
}

.itemsList div:hover {
    background-size: 100% var(--border-width);
}

.fromCenter {
    background-position: 50% calc(100% - var(--bottom-distance));
}

.lastSection{
    animation: 2s anim-lineUp ease-out; 
    margin: 0 5%;
    width:27%;
}

.heading{
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-end;
    color: #FAF6E2;
    opacity: 0.8;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: 'Open Sans', sans-serif;
}

.outer{
    opacity: 0.8;
    border:2px solid #FAF6E2;
    padding:5px;
}
.inner{
    border:1px solid #FAF6E2;
    padding:10px;
    display: flex ;
    justify-content: space-between;
    align-items: center;
}

.inner input{
    font-family: 'Open Sans', sans-serif;
    background: none;
    font-size:18px;
    color: #FAF6E2;
    opacity: 0.8;
    padding:10px;
    letter-spacing: 1px;
    width: 100%;
    border: none;
}
.inner input:focus{
    outline: none;
}

.inner input::placeholder{
    color:#FAF6E2;
}

.Arrow{
    position: relative;
    z-index: 100;
    cursor: pointer;
}

.Diamond{
    position: relative;
    z-index: 50;
    margin-left:-10px;
}

.ArrowAnimated{
    position: relative;
    z-index: 50;
    transform: translateX(7px);
    transition: all 0.5s linear;
}
.DiamondAnimated{
    display: none;
}

.membersArea{
    display: flex;
    align-items: center;
}
.membersArea span{
    font-size: 20px;
    color:white;
    font-family: 'Open Sans', sans-serif;
    margin-right: 15px;
}

.membersArea img{
    margin-top: 4px;
}


@media screen and (max-width: 960px){
    .main{
        overflow: scroll;
    }
    .crossLogo{
        top:3%;
    }
    .crossLogo img{
        width: 70px;
    }
    .btn{
        top:5%;
    }
    .itemsList{
        margin-top: 35%;
    }
    .itemsList div{
        font-size: 30px;
        margin-bottom: 25px;
    }
    .lastSection{
        margin-top: 5%;
        width: 90%;
    }
    .lastSection div a img{
        width: 40px;
    }
    .membersArea{
        margin-top: 20px;
    }
}
