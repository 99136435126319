.Container {
  background-image: url("../../assets/Roadmap/Roadmap.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  height: auto;
  animation: fadeIn 3s ease-out;
}

.main {
  padding-top: 10%;
  margin-left: 5%;
  margin-right: 5%;
  color: #faf6e2e5;
  font-family: "Open Sans", sans-serif;
}

.Heading {
  animation: anim-lineUp 3s ease-out;
  margin-left: 5%;
  font-weight: 400;
  opacity: 0.8;
  color: #faf6e2;
  font-family: "Open Sans", sans-serif;
}

.Description {
  animation: anim-lineUp 3s ease-out;
  font-family: "Open Sans", sans-serif;
  color: #faf6e2;
  opacity: 0.8;
  font-weight: 300;
  font-size: 18px;
  width: 53%;
  margin-top: 3%;
}

.grid {
  animation: anim-lineUp 4s ease-out;
  margin-top: 1%;
  padding-bottom: 3%;
}
.grid img {
  width: 100%;
  filter: brightness(115%);
  /* filter:saturate(120%); */
}

.phoneGrid {
  display: none;
  animation: anim-lineUp 4s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 960px) {
  .Container{
    background: none;
    background-color: #494137;
    opacity: 0.95;
  }
  .main {
    padding-top: 22%;
  }
  .Heading {
    margin-left: 20%;
    font-size: 25px;
  }
  .Description {
    font-size: 20px;
    width: 100%;
  }
  .grid {
    display: none;
  }
  .phoneGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5% 3%;
  }
  .phoneGrid img {
    margin-bottom: 10%;
    filter: brightness(115%);
    width: 340px;
    margin-right:10px;
  }
}
