.main{
    padding: 2% 5%;
    position: relative;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clickable{
    width: 110px;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.phoneNavBar{
    display: none;
}

.vsLogo{
    margin-left: 10px;
}

.membersArea{
    cursor: pointer;
    display: flex;
    margin-top:8px;
    opacity: 0.9;
    justify-content: center;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }

    20% {
        opacity: 0;
    }

    50% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@media screen and (max-width: 600px){
    .main{
        padding: 5%;
    }

    .clickable{
        display: none;
    }

    .phoneNavBar{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .membersArea{
        display: none;
    }
}