.BulletOuterActive {
  box-sizing: border-box;
  width: 14.14px;
  height: 14.14px;
  border: 1px solid #f879bd;
  transform: rotateZ(45deg);
  padding: 3px;
}

.BulletOuterInActive {
  box-sizing: border-box;
  width: 14.14px;
  height: 14.14px;
  border: 1.52759px solid #FAF6E2;
  transform: rotateZ(45deg);
  padding: 3px;
}

.BulletInnerInActive {
  width: 100%;
  height: 100%;
}

.BulletInnerActive {
  background-color: #FAF6E2;
  width: 100%;
  height: 100%;
}

.BulletItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BulletItemTextActive {
  margin-left: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 49px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: rgba(250, 246, 226, 0.8);
  padding: 0px 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid#f879bd;
  text-decoration: none;
  cursor: pointer;
}

.BulletItemTextInActive {
opacity: 0.8;
  margin-left: 15px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  color: rgba(250, 246, 226, 0.8);
  text-decoration: none;
  cursor: pointer;
}
