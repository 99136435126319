.home {
  background-color: #faf6e2;
  text-decoration: none;
  color: black;
  cursor: default;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-position: center center;
  justify-content: center;
  align-items: center;
  position: relative;
}

.arrive {
  font-family: "Open Sans", sans-serif;
  font-size: 51px;
  font-weight: 300;
  opacity: 0.8;
  text-align: center;
}

.seasonOneCannes {
  opacity: 0.8;
  font-family: "Open Sans", sans-serif;
  font-size: 34px;
  font-weight: 300;
  margin: 10px 0;
  text-align: center;
}

.leDebut {
  font-family: "Lalezar", cursive;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #f879bd;
  color: #f3aac5;
  font-size: 68px;
  text-align: center;
  font-weight: 400;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.click {
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-size: x-small;
}

.yatchImg {
  position: absolute;
  bottom: 17%;
  right: 5%;
}

@media screen and (max-width: 600px) {
  .yatchImg {
    bottom: 7%;
  }
}
