.main {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/HomePage/HomePage.png");
  /* background-image: url("../../assets/HomePage/HomePage.png");   */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2s ease-out;
  /* filter: brightness(110%); */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.heading1 {
  position: relative;
  margin-top: 5%;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f879bd;
  color: #f0eacb;
  letter-spacing: 5px;
  font-family: "Lalezar", cursive;
  font-weight: 500;
  font-size: 95px;
  animation: 5s anim-lineUp ease-out;
}

.heading2 {
  position: relative;
  margin-top: -30px;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f879bd;
  color: #f0eacb;
  letter-spacing: 5px;
  font-family: "Lalezar", cursive;
  font-weight: 500;
  font-size: 95px;
  animation: 5s anim-lineUp ease-out;
}
.heading3 {
  position: relative;
  margin-top: -80px;
  text-align: center;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #f879bd;
  color: #f0eacb;
  letter-spacing: 5px;
  font-family: "MonteCarlo", cursive;
  font-weight: 500;
  font-size: 194px;
  animation: 5s anim-lineUp ease-out;
}

.heading4 {
  margin-top: -30px;
  text-align: center;
  color: #f0eacb;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 30px;
  animation: 5s anim-lineUp ease-out;
}

.btn {
  display: none;
  animation: 5s anim-lineUp ease-out;
}

@media screen and (max-width: 600px) {
    .btn{
        display: block;
        margin-top: 4%;
    }
    .heading1{
      margin-top: 0%;
      font-size: 45px;
      color:#F3AAB7;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #f879bd;
    }
    .heading2{
      font-size: 45px;
      color: #F3AAB7;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #f879bd;
    }
    .heading3{
      font-weight: bolder;
      margin-top: 0px;color: #F3AAB7;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #f879bd;
      font-size: 85px;
    }
    .heading4{
        margin: 0 3% ;
    }
}
