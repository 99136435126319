.main {
  background-color: #faf6e2;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  padding-top: 8%;
  padding-bottom: 1%;
  padding-left: 6%;
  padding-right: 6%;
  animation: fadeIn 3s ease-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.leftSide {
  margin-top: 1%;
  width: 100%;
  animation: 4s anim-lineUp ease-out;
}

.rightSide {
  margin-top: 3%;
  width: 50%;
  animation: 4s anim-lineUp ease-out;
}

.heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  opacity: 0.8;
  font-size: 14px;
}

.quote {
  font-family: "Open Sans", sans-serif;
  width: 43%;
  margin-top: 20px;
  font-weight: 300;
  line-height: 40px;
  opacity: 0.8;
  /* letter-spacing: 2px; */
  font-size: 30px;
}

.description {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  width: 50%;
  font-size: 18px;
  margin-top: 45px;
}

.description span {
  font-weight: 400;
}


.description2 {
  display: none;
}

.btn {
  display: none;
}

@media screen and (max-width: 960px) {
  .main {
    padding-top: 25%;
    flex-direction: column;
  }
  .heading {
    font-size: 22px;
  }
  .quote {
    width: 100%;
    font-size: 30px;
    padding-right: 6%;
  }
  .description {
    display: none;
  }
  .rightSide img {
    margin-top: 15%;
    width: 200%;
  }
  .description2 {
    display: block;
    margin-top: 5%;
    margin-bottom: 5%;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
  }
  .description2 span {
    font-weight: 400;
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 25%;
  }
  .btn img {
    width: 150px;
  }
}
