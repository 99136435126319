.Container {
  background: #494137;
  padding-bottom: 5%;
  color: white;
  opacity: 0.9;
  min-height: 100vh;
  animation: fadeIn 1s ease-out;
  font-family: "Open Sans", sans-serif;
}

.Heading {
  padding-top: 8%;
  font-family: "Open Sans", sans-serif;
  padding-left: 15%;
  color: #faf6e2;
  font-weight: 300;
  font-size: 16px;
  opacity: 0.8;
  animation: anim-lineUp 3s ease-out;
}

.form {
  margin-top: 5%;
  display: flex;
  animation: anim-lineUp 4s ease-out;
  flex-direction: column;
  align-items: center;
}

.inputBox {
  margin-bottom: 20px;
}

.inputBox label {
  font-weight: 400;
  opacity: 0.7;
  margin-left: 10px;
}

.input {
  background: none;
  border: 2px solid #faf6e2;
  width: 400px;
  margin-top: 10px;
  font-size: 18px;
  padding: 10px 9px;
  border-radius: 10px;
  color: #faf6e2;
  opacity: 0.6;
}

.input::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.8;
}

.textarea {
  background: none;
  border: 2px solid #faf6e2;
  width: 400px;
  margin-top: 10px;
  font-size: 18px;
  padding: 10px 9px;
  color: #faf6e2;
  opacity: 0.6;
  border-radius: 10px;
  resize: none;
  height: 200px;
}

.textarea:focus {
  outline: none;
}

.input:focus {
  outline: none;
}

.submitBtn {
  margin-top: 2%;
}

.ModalContainer {
  background-color: #faf6e2;
  display: flex;
  align-items: center;
  width: 50%;
  padding: 5%;
  flex-direction: column;
}

.Text {
  text-align: center;
  color: black;
  margin-top: 2%;
  font-family: "Open Sans", sans-serif;
  width: 80%;
  font-size: 27px;
}

.submitBtn button {
  background: none;
  border: none;
  letter-spacing: 1px;
  color: #faf6e2;
  cursor: pointer;
  font-weight: 400;
  opacity: 0.8;
  font-family: "Open Sans", sans-serif;
  padding: 5px 0px;
  font-size: 28px;
  border-bottom: 2px solid #6396c5;
}

.cross {
  background-color: aqua;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }

  20% {
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media screen and (max-width: 960px) {
  .Heading {
    padding-top: 35%;
  }
  .input {
    width: 340px;
  }
  .textarea{
    width: 340px;
  }
  input[type="date"] {
    display: block;

    /* Solution 1 */
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 50%;

    /* Solution 2 */
    /* min-width: 96%; */
  }
}
